import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

// Create the upload link for handling file uploads (multipart/form-data)
const uploadLink = createUploadLink({
  uri: 'https://api.glidentry.com/',  // Your GraphQL endpoint
});

// Create the standard HTTP link for non-upload requests
const httpLink = new HttpLink({
  uri: 'https://api.glidentry.com/',  // Your GraphQL endpoint
});

// Helper function to detect if variables contain a file upload
const isFileUpload = (variables) => {
  console.log('Checking for file upload:', variables);
  let containsFile = false;

  const checkForFile = (value) => {
    if (value instanceof File) {
      containsFile = true;
    } else if (Array.isArray(value)) {
      value.forEach((item) => checkForFile(item));
    } else if (value && typeof value === 'object') {
      Object.values(value).forEach((item) => checkForFile(item));
    }
  };

  
    // Iterate through the variables and check each one
    Object.values(variables).forEach((value) => {
      checkForFile(value);  // Use the recursive function to inspect the value
    });

  // Log whether the operation involves a file upload
  if (containsFile) {
    console.log('File upload detected:', variables);
  } else {
    console.log('No file upload detected:', variables);
  }

  return containsFile;
};

// Set up context for authorization and any CSRF headers, if needed
const authLink = setContext((operation, { headers }) => {
  // Only skip Authorization for signup
  const isSignupOperation = operation.operationName === 'RegisterOrganizer';

  // Log operation details for debugging
  console.log('Apollo Operation Auth Check', {
    operationName: operation.operationName,
    isSignupOperation
  });

  // For signup, return headers without Authorization
  if (isSignupOperation) {
    console.log('Skipping Authorization for signup');
    return { headers };
  }

  // For all other operations (including login), add token
  const token = localStorage.getItem('authToken');
  const newHeaders = {
    ...headers,
    Authorization: token ? `Bearer ${token}` : '',
  };

  return { headers: newHeaders };
});
// Combine the authLink with both links and split based on whether it's a file upload
const link = ApolloLink.split(
  (operation) => {
    const isUpload = isFileUpload(operation.variables);
    
    // Log which link is being used
    if (isUpload) {
      console.log('Using uploadLink for file uploads.');
    } else {
      console.log('Using httpLink for non-file uploads.');
    }

    return isUpload;
  },
  uploadLink,  // Use uploadLink for file uploads
  httpLink     // Use httpLink for regular GraphQL requests
);

// Create Apollo Client
const client = new ApolloClient({
  link: authLink.concat(link),  // Combine authLink with split link
  cache: new InMemoryCache(),
});

const ApolloProviderSetup = ({ children }) => (
  <ApolloProvider client={client}>
    {children}
  </ApolloProvider>
);

export default ApolloProviderSetup;
