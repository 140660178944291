import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { gql, useMutation } from '@apollo/client';
import { useAuth } from '../context/AuthContext';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const AUTH_ORGANIZER_MUTATION = gql`
  mutation AuthOrganizer($input: iAuthOrganizer!) {
    authOrganizer(input: $input) {
      token
      organizer {
        firstName
        email
      }
    }
  }
`;

const SignInPage = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();
  const [authOrganizer, { data, error: gqlError }] = useMutation(AUTH_ORGANIZER_MUTATION);

  useEffect(() => {
    if (data?.authOrganizer?.token) {
      login({
        email: data.authOrganizer.organizer.email,
        firstName: data.authOrganizer.organizer.firstName,
        token: data.authOrganizer.token,
      });
      // setSuccess(true);
      setError('');
      setTimeout(() => {
        navigate('/home');
      }, 1000);
    }
    if (gqlError) {
      setLoading(false);
    const errorMessage = gqlError?.graphQLErrors?.[0]?.extensions?.wrongConditions || [];

	if (errorMessage && errorMessage.includes('USER_INPUT_ERROR')) {
        setError('The email or password you entered is incorrect. Please try again.');
      } else if (errorMessage.includes('user not found')) {
        setError('No account found with this email or phone number.');
      } else if (errorMessage.includes('password is required')) {
        setError('Please enter your password.');
      } else {
        // Extract wrong conditions if available
        const wrongConditions = gqlError?.graphQLErrors?.[0]?.extensions?.wrongConditions;
        if (wrongConditions && wrongConditions.length > 0) {
          setError(wrongConditions.join(', '));
        } else {
          setError('An unexpected error occurred. Please try again later.');
        }
      }
    }
  }, [data, gqlError, login, navigate]);

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+$/.test(email);
  const isValidPhoneNumber = (phoneNumber) => /^\d{10}$/.test(phoneNumber);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    let input = {};
    if (isValidEmail(identifier)) {
      input = { email: identifier, password };
    } else if (isValidPhoneNumber(identifier)) {
      input = { phoneNumber: identifier, password };
    } else {
      setError('Please enter a valid email or phone number.');
      setLoading(false);
      return;
    }

    try {
      await authOrganizer({ variables: { input } });
    } catch (err) {
      setError('Sign-in failed. Please try again later.');
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = () => {
    navigate('/sign-up');
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <Layout>
      <div className="signin-container">
        <h1>Login</h1>
        <form onSubmit={handleSubmit} className="signin-form">
          <TextField
            label="Email or Phone Number"
            fullWidth
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
            margin="normal"
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            margin="normal"
            InputProps={{
              endAdornment: (
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  onClick={togglePasswordVisibility}
                  style={{ cursor: 'pointer' }}
                />
              ),
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '8px',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              }
              label="Remember me"
              style={{ marginLeft: '0', fontSize: '0.7rem' }}
            />
            <Button
              onClick={handleForgotPassword}
              style={{
                textTransform: 'none',
                fontSize: '0.7rem',
                color: '#020420',
                padding: '0',
                maxWidth: '30%',
                justifyContent: 'right',
              }}
              variant="text"
            >
              Forgot password?
            </Button>
          </div>
          {error && (
              <Alert severity="error" style={{ marginTop: '16px' }}>
                {error}
              </Alert>
            )}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            style={{
              marginTop: '16px',
              backgroundColor: '#020420',
              color: 'white',
            }}
          >
            {loading ? 'Signing In...' : 'Sign In'}
          </Button>
          <p style={{ marginTop: '16px', textAlign: 'center' }}>
            Don't have an account?{' '}
            <span
              onClick={handleSignUp}
              style={{ cursor: 'pointer', color: '#020420' }}
            >
              Create an account
            </span>
          </p>
        </form>
      </div>
    </Layout>
  );
};

export default SignInPage;
