import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { gql, useMutation } from '@apollo/client';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const REGISTER_ORGANIZER_MUTATION = gql`
  mutation RegisterOrganizer($input: iRegisterOrganizer!) {
    registerOrganizer(input: $input)
  }
`;

const SignUpPage = () => {
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    preferredName: '',
    agreementChecked: false,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [openTerms, setOpenTerms] = useState(false); // State to control terms modal

  const navigate = useNavigate();
  const [registerOrganizer] = useMutation(REGISTER_ORGANIZER_MUTATION);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });

    if (name === 'password') {
      setPasswordStrength(calculatePasswordStrength(value));
    }
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 5) strength += 20;
    if (password.length > 8) strength += 20;
    if (/[A-Z]/.test(password)) strength += 20;
    if (/[0-9]/.test(password)) strength += 20;
    if (/[^A-Za-z0-9]/.test(password)) strength += 20;
    return strength;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formState.agreementChecked) {
      setError('You must agree to the terms and conditions.');
      setLoading(false);
      return;
    }

    if (passwordStrength < 80) {
      setError('Password is not strong enough. Please use a stronger password.');
      setLoading(false);
      return;
    }

    if (formState.password !== formState.confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    const input = {
      email: formState.email,
      password: formState.password,
      phoneNumber: formState.phoneNumber || null,
      firstName: formState.firstName || null,
      lastName: formState.lastName || null,
      preferredName: formState.preferredName || null,
    };

    try {
      await registerOrganizer({ variables: { input } });
      setSuccess(true);
      setError('');
      setTimeout(() => navigate('/sign-in'), 2000);
    } catch (err) {
      const wrongConditions =
        err?.graphQLErrors?.[0]?.extensions?.wrongConditions || [];
      const message = wrongConditions.length
        ? wrongConditions.join(', ')
        : 'An unexpected error occurred.';

      setError(message);
      setLoading(false);
    }
  };

  return (
    <Layout>
      {!success ? (
        <div className="signup-container">
          <h1>Create an Account</h1>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              name="email"
              type="email"
              fullWidth
              value={formState.email}
              onChange={handleInputChange}
              required
              margin="normal"
            />
            <TextField
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              value={formState.password}
              onChange={handleInputChange}
              required
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="password-strength-bar" style={{ marginTop: '8px' }}>
              <div
                className="strength-meter"
                style={{
                  width: `${passwordStrength}%`,
                  height: '8px',
                  backgroundColor:
                    passwordStrength < 50
                      ? 'red'
                      : passwordStrength < 80
                      ? 'orange'
                      : 'green',
                }}
              ></div>
            </div>
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              value={formState.confirmPassword}
              onChange={handleInputChange}
              required
              margin="normal"
            />
            <TextField
              label="Phone Number (optional)"
              name="phoneNumber"
              type="text"
              fullWidth
              value={formState.phoneNumber}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              label="First Name (optional)"
              name="firstName"
              type="text"
              fullWidth
              value={formState.firstName}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              label="Last Name (optional)"
              name="lastName"
              type="text"
              fullWidth
              value={formState.lastName}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              label="Preferred Name (optional)"
              name="preferredName"
              type="text"
              fullWidth
              value={formState.preferredName}
              onChange={handleInputChange}
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="agreementChecked"
                  checked={formState.agreementChecked}
                  onChange={(e) =>
                    setFormState({ ...formState, agreementChecked: e.target.checked })
                  }
                  required
                />
              }
              label={
                <>
                  I agree to the{' '}
                  <Link
                    onClick={() => setOpenTerms(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    terms and conditions
                  </Link>
                </>
              }
            />
            {/* {error && <p className="error-message">{error}</p>} */}
            {error && (
              <Alert severity="error" style={{ marginTop: '16px' }}>
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
              style={{
                marginTop: '16px',
                backgroundColor: '#020420',
                color: 'white',
              }}
            >
              {loading ? 'Signing Up...' : 'Sign Up'}
            </Button>
            <p style={{ marginTop: '16px', textAlign: 'center' }}>
              Already have an account?{' '}
              <Link
                onClick={() => navigate('/sign-in')}
                style={{ cursor: 'pointer', color: '#020420' }}
              >
                Sign In
              </Link>
            </p>
          </form>
        </div>
      ) : (
        <div className="success-message">
          <h1>Registration Successful!</h1>
          <p>Redirecting you to the home page...</p>
        </div>
      )}

      {/* Terms and Conditions Modal */}
      <Modal
        open={openTerms}
        onClose={() => setOpenTerms(false)}
        aria-labelledby="terms-and-conditions-title"
        aria-describedby="terms-and-conditions-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="terms-and-conditions-title">Terms and Conditions</h2>
          <p id="terms-and-conditions-description">
            Here are the terms and conditions. Please read them carefully before
            proceeding.
          </p>
          <Button
            onClick={() => setOpenTerms(false)}
            style={{ marginTop: '16px', backgroundColor: '#020420', color: 'white' }}
            fullWidth
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Layout>
  );
};

export default SignUpPage;
