import React,{useEffect} from "react";
import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Loader } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
// import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { FaceLivenessDetectorCore } from '@aws-amplify/ui-react-liveness';
import { VALIDATE_ENTRY_TO_EVENT,CREATE_EVENT_ENTRY_STREAM } from "../graphql/mutations";
import { useNavigate } from "react-router-dom";

// import FaceLiveness from './FaceLiveness';
// import ReferenceImage from './ReferenceImage';
import {
  View,
  Flex,
} from '@aws-amplify/ui-react';
import { useLocation } from "react-router-dom";

import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { useMutation } from "@apollo/client";
Amplify.configure(awsconfig);

const LivePage = () => {
  const [loading, setLoading] = React.useState(true);
    const [sessionId, setSessionId] = React.useState(null)
    // const [faceLivenessAnalysis, setFaceLivenessAnalysis] = useState(null);
    const location = useLocation();
    const { streamId, eventId } = location.state || {};
    const navigate = useNavigate();
    const [createEventEntryStream] = useMutation(CREATE_EVENT_ENTRY_STREAM);


    // const {data, loading: loadingData, error} = useMutation(VALIDATE_ENTRY_TO_EVENT);

    useEffect(() => {
      // const fetchOrReadLivenessSession = async () => {
      // const storedSessionId = localStorage.getItem('streamId');
          
      //     if (storedSessionId) {
      //       console.log('Using sessionId from localStorage:', storedSessionId);
      //       setSessionId(storedSessionId);
      //       setLoading(false);
      //     } else {
      //       alert('No sessionId found in localStorage');
      //       }
      //   };
      //   fetchOrReadLivenessSession();
      if (!streamId || !eventId) {
        alert("No state found! Redirecting...");
        navigate('/check-in');
      } else {
        setSessionId(streamId);
        setLoading(false);
        // console.log("StreamId:", streamId);
        // console.log("EventId:", eventId);
      }
    }, [streamId, eventId, navigate]);


      const [validateEntryToEvent] = useMutation(VALIDATE_ENTRY_TO_EVENT);


      const handleAnalysisComplete = async () => {
        try {
          setLoading(true);
  
          const {data} = await validateEntryToEvent({
            variables: {
              input: {
                sessionId,
              },
            },
          });

          const response = data.validateEntryToEvent;
          
          if(response.status === "SUCCEEDED") {
            const userName = response.user.preferredName || response.user.firstName;
            const checkInMessage = `${userName} has been successfully checked in.`;
            alert(checkInMessage);

            speakMessage(checkInMessage);

            const payload = {
              eventId: eventId, // Dynamically set the selected event ID
            };
      
            // Call the CreateEventEntryStream mutation
            const { data: createEventData } = await createEventEntryStream({
              variables: { input: payload },
            });
      
            
            if (createEventData?.createEventEntryStream) {
              const newStreamId = createEventData.createEventEntryStream;

              // Ask the user if they want to check in another person
              // const checkAnother = window.confirm(
              //   "Do you want to check in another person?"
              // );

              setSessionId(newStreamId);

            // if (checkAnother) {
              // Update sessionId with the new streamId
              // setSessionId(newStreamId);
            // } else {
            //   // Navigate to a different page or show a message to stop
            //   alert("Check-in process stopped.");
            //   navigate('/check-in');
            // }
      }
    }
  } catch (error) {
    // alert("Failed to validate entry. Please try again.");
    // const failureMessage = "Validation failed. Please try again.";

    const faceNotRecognizedMessages = [
      "Oops, Try Again",
      "Uh-oh! We can’t find you in the system. Did you change your hair? Let’s try that again!",
      "Hmm… our AI is squinting really hard. Step a little closer and give it another shot!",
      "Glide’s having a moment… Try one more time?",
      "Not working? Maybe strike a cooler pose and let’s see if that helps!"
    ];


    const randomMessage = faceNotRecognizedMessages[Math.floor(Math.random() * faceNotRecognizedMessages.length)];

    // speakMessage(failureMessage);
    // const hiddenButton = document.getElementById("hiddenSpeechButton");
    // if (hiddenButton) {
    //   // Simulate a click on the button
    //   hiddenButton.click();
    // }
    speakMessage(randomMessage);
    alert(randomMessage);

    // Text-to-Speech for failed validation
    navigate(-1);
  } finally {
    setLoading(false);
  }
};


const speakMessage = (message) => {
  const utterance = new SpeechSynthesisUtterance(message);
  utterance.lang = "en-US"; // Set the language (adjust as needed)
  utterance.pitch = 1; // Adjust pitch (0-2)
  utterance.rate = 1; // Adjust rate (0.1-10)
  utterance.volume = 1; // Adjust volume (0-1)
  window.speechSynthesis.speak(utterance);
};


  // const getfaceLivenessAnalysis = (faceLivenessAnalysis) => {
  //   if (faceLivenessAnalysis !== null) {
  //     setFaceLivenessAnalysis(faceLivenessAnalysis)
  //   }
  // }

  // const tryagain = () =>{
  //   setFaceLivenessAnalysis(null)
  // }

  const credentialProvider = async () => {
    try {
      // Fetch the credentials from your custom source
      console.log("Fetching credentials...");
      const credentials = await fetchMyCustomCredentials();
  
      // Validate the fetched credentials
      if (
        !credentials ||
        !credentials.accessKeyId ||
        !credentials.secretAccessKey
      ) {
        // throw new CredentialsProviderError("Invalid credentials");
        console.error("Invalid credentials");
      }
  
      // Return the credentials in the required format
      return {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken, // Optional
      };
    } catch (error) {
      console.error("Error fetching credentials:", error);
    }
  };

  const fetchMyCustomCredentials = async () => {
    console.log("Access Key ID:", process.env.REACT_APP_ACCESS_KEY_ID);
console.log("Secret Access Key:", process.env.REACT_APP_SECRET_ACCESS_KEY);

    return {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    };
  };
  

  const handleError = (error) => {
    const errorMessage = String(error.message || error.error || "");
    if (errorMessage.includes("Liveness session has results available")) {
      alert("Liveness session has results available. Please try again.");
      navigate(-1);
    }
    else if (errorMessage.includes("Liveness session has expired")) {
      alert("Liveness session has expired. Please try again.");
      navigate(-1);
    }
    console.error("Error:", error);
  }
  


  return (
    <ThemeProvider>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
      >
        <View
          as="div"
          maxHeight="600px"
          height="600px"
          width="740px"
          maxWidth="740px"
        >
          {loading ? (
                <Loader />
            ) : (
                <FaceLivenessDetectorCore
                    sessionId={sessionId}
                    region="us-east-1"
                    onAnalysisComplete={handleAnalysisComplete}
                    onError={handleError}
                    config={{ credentialProvider }}
                />
            )}
        </View>
        {/* <View>
  <button
    style={{
      padding: "10px 20px",
      fontSize: "16px",
      cursor: "pointer",
      backgroundColor: "#4CAF50",
      color: "white",
      border: "none",
      borderRadius: "5px",
      marginTop: "20px",
    }}
    onClick={() => speakMessage("This is a test of the Text-to-Speech system.")}
  >
    Test Text-to-Speech
  </button>
</View> */}
<button
  id="hiddenSpeechButton"
  style={{ display: "none" }}
  onClick={() => speakMessage("Oops! Something went wrong. Please try again.")}
/>
      </Flex>
    </ThemeProvider>


  );
}

export default LivePage;